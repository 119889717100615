@import 'Styles/includes';

.Browsealoud {
    $root: &;

    &--Desktop {
        display: none;

        @include media(lg) {
            display: flex;
            align-items: center;
        }
    }

    &__ButtonWrapper {
        #{$root}--Desktop & {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    &__Button {
        border: none;
        background-color: transparent;
        white-space: nowrap;
        font-size: 1.5rem;
        color: $header-color;
        font-weight: 600;
        font-family: $base-font;
        cursor: pointer;

        &:hover {
            #{$root}--Desktop & {
                color: $header-color-hover;
            }
        }

        #{$root}--Desktop & {
            position: relative;
            transition: color 0.25s ease;
            margin-left: 16px;
        }

        #{$root}--Mobile & {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 2.5rem;
            padding: 25px 24px;
        }

        #{$root}--MenuService & {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.2rem;
            color: #fff;
            padding: 13px 25px;

            &:hover {
                @include media(lg) {
                    color: $header-service-color-hover;
                }
            }

            @include media(lg) {
                font-size: 1.2rem;
                color: $header-service-color;
                padding: 0 16px 0 0;
                margin: 0 16px;
                border-right: 1px solid $header-service-border;
            }
        }

        #{$root}--Vasterbotten & {
            color: $gray-60;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('#{$publicpath}img/sound.svg') no-repeat center center;
            background-size: contain;

            #{$root}--Mobile &,
            #{$root}--Sensus & {
                background-image: url('#{$publicpath}img/sound--white.svg');
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            #{$root}--Desktop & {
                position: absolute;
                top: 2px;
                left: -18px;
            }

            #{$root}--MenuService & {
                top: 0;
                width: 12px;
                height: 12px;

                @include media(lg) {
                    background-image: url('#{$publicpath}img/sound--black.svg');
                }
            }

            #{$root}--Vasterbotten & {
                background-image: url('#{$publicpath}img/sound--black.svg');
            }
        }

        &--Loading::before {
            opacity: 0;
        }
    }

    &__Loader {
        @include u-loader(15px);

        left: -20px;
        right: auto;
        display: none;

        #{$root}__Button--Loading & {
            display: block;
            opacity: 1;

            &::before {
                border-width: 2px;

                &:global {
                    animation: spin 1.1s infinite linear;
                }
            }
        }
    }
}
