@import 'Styles/includes';

/**
*
* Button
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Button {
    $root: &;

    text-decoration: none;
    border-radius: 4px;
    color: $button-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: proxima-nova, Arial;
    background-color: $button-bg;
    border: none;
    padding: 14px 28px;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.36px;
    line-height: 1;

    &:hover {
        background: $button-bg-hover;

        @media (hover: none) {
            background: $button-bg;
        }
    }

    &:active {
        background: $button-bg-active;
    }

    &:disabled {
        background-color: #a9a6a7;
        cursor: default;
    }

    &:not([href]) {
        @media print {
            display: none !important;
        }
    }

    &--Small {
        padding: 12px 18px;
        font-size: 1.3rem;
    }

    &--Large {
        font-size: 1.8rem;
        width: 100%;
        text-align: center;
    }

    &--White {
        color: $text;
        background-color: white;
        border: 1px solid $gray-15;

        &:hover {
            background-color: $gray-10;
            @media (hover: none) {
                background-color: $gray-10;
            }
        }

        &:active {
            background-color: $gray-5;
        }
    }

    &--Fullwidth {
        width: 100%;
    }

    &--Purple {
        background-color: $purple;

        &:hover {
            background-color: $purple-70;
            @media (hover: none) {
                background-color: $purple;
            }
        }
    }

    &--Yellow {
        background-color: $yellow;

        &:hover {
            background-color: $yellow-60;
            @media (hover: none) {
                background-color: $yellow;
            }
        }
    }
}
