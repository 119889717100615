@import 'Styles/includes';

/**
*
* NavMain
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NavMain {
    $root: &;

    position: relative;
    display: block;
    z-index: 50;

    @include media(lg) {
        position: static;
        display: none;
    }

    @media print {
        display: none !important;
    }

    &__WaypointContainer {
        position: absolute;
        top: -52000px;
    }

    &__Overlay {
        position: fixed;
        visibility: hidden;
        top: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: top 0.2s ease-in, opacity 0.2s ease-in,
            visibility 0.2s ease-in;

        #{$root}--Open & {
            visibility: visible;
            top: 0;
            opacity: 1;
        }
    }

    &__Waypoint {
        margin-top: 50px;
        position: absolute;
    }
}
