@import 'Styles/includes';

/**
*
* InfoBox
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.InfoBox {
    $root: &;

    background-color: $gray-40;
    color: $text;
    padding: 15px;
    line-height: 1.25;

    @include media(md) {
        padding: 30px;
    }

    &__Content {
        margin: 0 auto;

        @include media(md) {
            max-width: maxwidth(article);
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 12px;
            font-weight: 800;
        }

        a {
            color: $text;
            text-decoration: underline;
        }
    }
}
