$publicpath: '../../public/' !default;

$font-proxima: proxima-nova, Arial, sans-serif;

$base-font: $font-proxima;

$maxwidths: (
    site: 952px,
    article: 1145px,
);

$breakpoints: (
    xs: 480px,
    sm: 768px,
    md: 960px,
    lg: 1024px,
    xl: 1280px,
);

// Map theme css variables to scss variables
// CSS variables are defined in themes
$selection-bg: var(--selection-bg);

$link-color: var(--link-color);

$red-icon-color: var(--red-icon-color);
$icon-color: var(--icon-color);

$header-height: var(--header-height);
$header-height-lg: var(--header-height-lg);
$service-height: 35.5px;

$header-bg: var(--header-bg);
$header-color: var(--header-color);
$header-color-hover: var(--header-color-hover);
$header-menu-item-bg: var(--header-menu-item-bg);
$header-menu-item-color-hover: var(--header-menu-item-color-hover);
$header-sub-color: var(--header-sub-color);
$header-sub-color-hover: var(--header-sub-color-hover);
$header-service-bg: var(--header-service-bg);
$header-service-color: var(--header-service-color);
$header-service-color-hover: var(--header-service-color-hover);
$header-service-border: var(--header-service-border);

$mobile-menu-bg: var(--mobile-menu-bg);
$mobile-menu-border: var(--mobile-menu-border);
$mobile-menu-list-bg: var(--mobile-menu-list-bg);
$mobile-menu-sub-bg: var(--mobile-menu-sub-bg);

$submenu-bg: var(--submenu-bg);
$submenu-bg-active: var(--submenu-bg-active);
$submenu-bg-hover: var(--submenu-bg-hover);

$footer-bg: var(--footer-bg);
$footer-color: var(--footer-color);
$footer-link-color: var(--footer-link-color);
$footer-border: var(--footer-border);

$button-bg: var(--button-bg);
$button-bg-hover: var(--button-bg-hover);
$button-bg-active: var(--button-bg-active);
$button-color: var(--button-color);

$checkbox-border: var(--checkbox-border);
$checkbox-bg: var(--checkbox-bg);

$radio-border: var(--radio-border);
$radio-bg: var(--radio-bg);

$error-color: var(--error-color);
$error-bg: var(--error-bg);

$warning-color: var(--warning-color);
$warning-bg: var(--warning-bg);

$tab-color: var(--tab-color);

$embed-message-bg: var(--embed-message-bg);

$quote-color: var(--quote-color);

$highlight-color: var(--highlight-color);

$input-select-bg: var(--input-select-bg);
$input-hover-bg: var(--input-hover-bg);

$loader-color: var(--loader-color);

$promo-bg: var(--promo-bg);
$promo-color: var(--promo-color);

$expandable-border: var(--expandable-border);
$expandable-toggle-active: var(--expandable-toggle-active);

// Hex variables
$white: #fff;
$black: #000;

$gray-5: #f8f8f8;
$gray-8: #f4f4f4;
$gray-10: #f2f2f2;
$gray-15: #ededed;
$gray-20: #d2d2d2;
$gray-25: #c2bbbc;
$gray-35: #9f9292;
$gray-40: #9a9798;
$gray-50: #616161;
$gray-60: #3E3234;
$gray-70: #3e3234;
$gray-90: #0b0b0b;

// Sensus colors, some are old
$red: #af1432;
$red-10: #EDD6DB;
$red-20: #DB7081;
$red-30: #cb2746;
$red-60: #9a0f2a;
$red-70: #8a0d25;

$purple: #501873;
$purple-70: #370b53;

$yellow: #ffa641;
$yellow-60: #ee9024;

$blue: #2087b1;

$green: #83a81c;

$beige: #796b6b;

// New Sensus colors
$sensus-red: $red;
$sensus-red-80: #71141B;
$sensus-red-60: #D27D76;
$sensus-red-40: #E1AAA2;
$sensus-red-20: #F0D5D0;
$sensus-red-10: #f7e8eb; // Added lighter variant, maybe should be changed

$sensus-purple: #614188;
$sensus-purple-80: #402F5A;
$sensus-purple-60: #A090BE;
$sensus-purple-40: #C0B5D5;
$sensus-purple-20: #DFD9EB;

$sensus-green: $green;
$sensus-green-80: #3D651D;
$sensus-green-60: #ABC380;
$sensus-green-40: #C9D7AC;
$sensus-green-20: #E5EBD7;

$sensus-blue: $blue;
$sensus-blue-80: #00526E;
$sensus-blue-60: #77B1D1;
$sensus-blue-40: #AACAE1;
$sensus-blue-20: #D7E4F0;

$sensus-yellow: #ECB421;
$sensus-yellow-80: #CA8C00;
$sensus-yellow-60: #FAD97F;
$sensus-yellow-40: #FCE5AE;
$sensus-yellow-20: #FEF2D8;

// New Gävleborg colors
$gavleborg-pink: #fe91af;
$gavleborg-pink-60: #8a3649;
$gavleborg-pink-80: #602532;
$gavleborg-yellow: #fcdb34;
$gavleborg-teal: #6cb0ab;
$gavleborg-green: #76bc75;

// System colors
$system-error: $sensus-red;
$system-error-10: $sensus-red-10;
$system-warning: $sensus-yellow;
$system-warning-10: $sensus-yellow-20;

/* Uses */
$black: $gray-90;
$text: $gray-70;
$footer: $gray-35;

// Theme colors, defined in Figma
$norrbotten-primary: $sensus-red;
$norrbotten-secondary-80: $sensus-red-80;
$norrbotten-secondary-60: $sensus-red-60;
$norrbotten-secondary-40: $sensus-red-40;
$norrbotten-secondary-20: $sensus-red-20;
$norrbotten-gray-70: $gray-70;
$norrbotten-gray-30: $gray-35;
$norrbotten-gray-10: $gray-20;
$norrbotten-gray-5: $gray-15;

// Same as norrbotten
$dalarna-primary: $sensus-red;
$dalarna-secondary-80: $sensus-red-80;
$dalarna-secondary-60: $sensus-red-60;
$dalarna-secondary-40: $sensus-red-40;
$dalarna-secondary-20: $sensus-red-20;
$dalarna-gray-70: $gray-70;
$dalarna-gray-30: $gray-35;
$dalarna-gray-10: $gray-20;
$dalarna-gray-5: $gray-15;

$vasterbotten-primary: $sensus-yellow-80;
$vasterbotten-secondary-80: $sensus-red-80;
$vasterbotten-secondary: $sensus-red;
$vasterbotten-tertiary: $sensus-yellow;
$vasterbotten-tertiary-60: $sensus-yellow-60;
$vasterbotten-tertiary-40: $sensus-yellow-40;
$vasterbotten-tertiary-20: $sensus-yellow-20;
$vasterbotten-gray-70: $gray-70;
$vasterbotten-gray-30: $gray-35;
$vasterbotten-gray-10: $gray-20;
$vasterbotten-gray-5: $gray-15;

$uppsala-primary: $sensus-blue-80;
$uppsala-primary-60: $sensus-blue-60;
$uppsala-primary-40: $sensus-blue-40;
$uppsala-secondary: $sensus-blue-60;
$uppsala-secondary-50: $sensus-blue;
$uppsala-tertiary: $sensus-purple;
$uppsala-tertiary-40: $sensus-purple-40;
$uppsala-quaternary-20: $sensus-red-20;
$uppsala-gray-70: $gray-70;
$uppsala-gray-30: $gray-35;
$uppsala-gray-10: $gray-20;
$uppsala-gray-5: $gray-15;

$stockholmnorr-primary: $sensus-green-80;
$stockholmnorr-secondary: $sensus-green;
$stockholmnorr-secondary-60: $sensus-green-60;
$stockholmnorr-secondary-40: $sensus-green-40;
$stockholmnorr-secondary-20: $sensus-green-20;
$stockholmnorr-gray-70: $gray-70;
$stockholmnorr-gray-30: $gray-35;
$stockholmnorr-gray-10: $gray-20;
$stockholmnorr-gray-5: $gray-15;

$gavleborg-primary: $gavleborg-pink-60;
$gavleborg-primary-40: $gavleborg-pink;
$gavleborg-primary-80: $gavleborg-pink-80;
$gavleborg-secondary: $gavleborg-yellow;
$gavleborg-tertiary: $gavleborg-teal;
$gavleborg-quaternary: $gavleborg-green;
$gavleborg-gray-70: $gray-70;
$gavleborg-gray-30: $gray-35;
$gavleborg-gray-10: $gray-20;
$gavleborg-gray-5: $gray-15;


/*
 * Sensus colors defined in admin for usage as backgrounds, with a specific contrast text color
 */
$sensus: (
    Gray: (color: $gray-10, contrast: $black),
    Red: (color: $sensus-red, contrast: white),
    Red80: (color: $sensus-red-80, contrast: white),
    Red60: (color: $sensus-red-60, contrast: white),
    Red40: (color: $sensus-red-40, contrast: $black),
    Red20: (color: $sensus-red-20, contrast: $black),
    Blue: (color: $sensus-blue, contrast: white),
    Blue80: (color: $sensus-blue-80, contrast: white),
    Blue60: (color: $sensus-blue-60, contrast: white),
    Blue40: (color: $sensus-blue-40, contrast: $black),
    Blue20: (color: $sensus-blue-20, contrast: $black),
    Purple: (color: $sensus-purple, contrast: white),
    Purple80: (color: $sensus-purple-80, contrast: white),
    Purple60: (color: $sensus-purple-60, contrast: white),
    Purple40: (color: $sensus-purple-40, contrast: $black),
    Purple20: (color: $sensus-purple-20, contrast: $black),
    Green: (color: $sensus-green, contrast: white),
    Green80: (color: $sensus-green-80, contrast: white),
    Green60: (color: $sensus-green-60, contrast: white),
    Green40: (color: $sensus-green-40, contrast: $black),
    Green20: (color: $sensus-green-20, contrast: $black),
    Yellow: (color: $sensus-yellow, contrast: $black),
    Yellow80: (color: $sensus-yellow-80, contrast: white),
    Yellow60: (color: $sensus-yellow-60, contrast: white),
    Yellow40: (color: $sensus-yellow-40, contrast: $black),
    Yellow20: (color: $sensus-yellow-20, contrast: $black),
    GavleborgGreen: (color: $gavleborg-green, contrast: $black),
    GavleborgTeal: (color: $gavleborg-teal, contrast: $black),
    GavleborgDarkPink: (color: $gavleborg-pink-60, contrast: white),
    GavleborgPink: (color: $gavleborg-pink, contrast: $black),
    GavleborgYellow: (color: $gavleborg-yellow, contrast: $black),
);

/*
 * For Agenda 2030
 * The colors are taken from the official global goals and the svg file https://www.globalgoals.org/resources/
 * and the same colors are added to the svg icons to match
 */
$agenda2030: (
    no-poverty: #E5243B,
    // 1. light red
    zero-hunger: #DDA63A,
    // 2. mustard yellow
    good-health: #4C9F38,
    // 3. forest green
    quality-education: #C5192D,
    // 4. maroon
    gender-equality: #FF3A21,
    // 5. bright red
    clean-water: #26BDE2,
    // 6. light blue
    affordable-clean-energy: #FCC30B,
    // 7. sun yellow
    decent-work-growth: #A21942,
    // 8. dark red
    industry: #FD6925,
    // 9. orange
    reduced-inequalities: #DD1367,
    // 10. deep pink
    sustainable-cities: #FD9D24,
    // 11. light orange
    responsible-consumption: #BF8B2E,
    // 12. brown
    climate-action: #3F7E44,
    // 13. dark green
    life-below-water: #0A97D9,
    // 14. blue
    life-on-land: #56C02B,
    // 15. bright green
    peace: #00689D,
    // 16. sea blue
    partnerships: #19486A,
    // 17. navy
);
