@import 'Styles/includes';

/**
*
* HeaderMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeaderMenu {
    $root: &;

    background-color: $header-bg;
    position: relative;
    //z-index: 1;
    transition: box-shadow 0.25s ease;

    &--Pinned {
        box-shadow: 0 3px 4px 0 rgba(black, 0.15);
    }

    &--OpenSubMenu {
        box-shadow: 0 0 0 0 rgba(black, 0.15);
    }

    @include media(lg) {
        display: block;
    }

    &__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100vw;
        height: 100vh;
        mix-blend-mode: multiply;
        background-color: rgba(30, 30, 30, 0.65);

        &:global {
            animation: fadein 0.25s ease forwards;
        }
    }

    &__Container {
        max-width: calc(#{maxwidth(article)} + 60px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;

        #{$root}--HasServiceMenu & {
            // justify-content: space-between;
        }

        #{$root}--Sensus & {
            justify-content: center;
        }

        @include media(lg) {
            padding: 0 30px;
            align-items: stretch;
            justify-content: start;
        }
    }

    &__MenuService {
        display: none;
        border-bottom: 1px solid $header-service-border;
        background-color: $header-service-bg;

        @include media(lg) {
            display: block;
        }
    }

    &__Extras {
        display: flex;
        position: relative;
    }

    &__MenuServiceWrapper {
        max-width: calc(#{maxwidth(article)} + 60px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 10px 30px;
    }

    &__MenuServiceLink {
        color: $header-service-color;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        text-decoration: none;
        padding-right: 16px;
        margin-right: 16px;
        border-right: 1px solid $header-service-border;

        &:hover {
            color: $header-service-color-hover;
        }
    }

    &__Logo {
        #{$root}--Sensus & {
            width: 105px;
            height: 22px;
        }

        #{$root}:not(#{$root}--Sensus) & {
            width: auto;
            height: 64px;
            max-width: 250px;
        }

        #{$root}--Uppsala & {
            @include media(lg) {
                height: 100px !important;
            }
        }

        #{$root}--Gavleborg & {
            @include media(lg) {
                height: 90px !important;
            }
        }
    }

    &__LogoLink {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(lg) {
            margin-right: 16px;
        }

        #{$root}--Sensus & {
            padding: 8px 0;
        }

        #{$root}:not(#{$root}--Sensus) & {
            padding: 0;
        }
    }

    &__Links {
        display: none;

        @include media(lg) {
            display: flex;
            min-height: 75px;
            width: 100%;
            margin-left: 8px;

            #{$root}--HasServiceMenu & {
                justify-content: flex-end;
            }
        }
    }

    &__ExternalIcon {
        display: flex;
        fill: $header-color;
        width: 14px;
        height: 14px;
        margin-left: 8px;

        #{$root}__Link:hover & {
            fill: $header-color-hover;
        }

        #{$root}__LinkItem--Open & {
            fill: $header-menu-item-color-hover;
            transform: rotate(180deg)
        }
    }

    &__Arrow {
        display: flex;
        fill: $header-color;
        width: 14px;
        height: 14px;
        margin-left: 4px;

        #{$root}__LinkItem--Open & {
            fill: $header-menu-item-color-hover;
            transform: rotate(180deg)
        }
    }

    &__LinkItem {
        align-items: center;
        display: inline-flex;
        height: 100%;

        &--Open {
            transition: background-color 0.25s ease;
            background-color: white;
        }
    }

    &__Link {
        cursor: pointer;
        align-items: center;
        display: inline-flex;
        font-size: 1.5rem;
        color: $header-color;
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        padding: 0 16px;
        height: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 2px;
            left: 8px;
            width: calc(100% - 16px);
            bottom: 0;
            background-color: $header-color-hover;
            opacity: 0;
        }

        &--NoMenu {
            overflow: hidden;

            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }

        #{$root}__LinkItem:hover & {
            color: $header-color-hover;
            transition: all 0.25s ease;

            &--Menu {
                color: $header-menu-item-color-hover;

                &::after {
                    background-color: $header-menu-item-color-hover;
                }
            }
        }
    }

    &__MenuExtras {
        position: absolute;
        right: 15px;
        display: flex;
        flex-direction: row;
        margin-left: 16px;

        @include media(lg) {
            position: relative;
        }

        #{$root}--HasServiceMenu & {
            @include media(lg) {
                display: none;
            }
        }
    }

    &__Search {
        font-family: proxima-nova, Arial;
        outline: none;
        cursor: pointer;
        align-items: center;
        display: inline-flex;
        font-size: 1.5rem;
        color: $header-color;
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        padding: 0 0 0 8px;
        height: 100%;
        border: none;
        background-color: $header-bg;

        @include media(lg) {
            padding: 0 0 0 24px;
        }

        #{$root}--HasServiceMenu & {

            @include media(lg) {
                padding: 0 16px 0 0;
                font-size: 1.2rem;
                font-weight: 400;
                color: $header-service-color;
                background-color: $header-service-bg;

                &:hover {
                    color: $header-service-color-hover;
                }

                span {
                    display: inline;
                }
            }
        }

        &:hover {
            color: $header-color-hover;
        }

        span {
            display: none;

            @include media(xl) {
                display: inline;
            }
        }

        &::before {
            content: '';
            width: 26px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center right;

            #{$root}--Sensus & {
                background-image: url('#{$publicpath}img/icon-search-thin--white.svg');
            }

            #{$root}:not(#{$root}--Sensus) & {
                background-image: url('#{$publicpath}img/icon-search-thin--black.svg');
            }

            @include media(lg) {
                margin-right: 8px;
            }

            #{$root}--HasServiceMenu & {
                @include media(lg) {
                    width: 16px;
                    height: 14px;
                    background-image: url('#{$publicpath}img/icon-search-thin--black.svg');
                }
            }
        }
    }

    &__TranslateDisableButton {
        margin-top: 8px;
    }

    &__Translate {
        font-family: proxima-nova, Arial;
        outline: none;
        cursor: pointer;
        align-items: center;
        font-size: 0;
        color: $header-color;
        display: inline-flex;
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        padding: 0 0 0 16px;
        height: 100%;
        border: none;
        background-color: $header-bg;

        @include media(xl) {
            font-size: 1.5rem;
        }

        #{$root}--HasServiceMenu & {

            @include media(lg) {
                padding: 0;
                color: $header-service-color;
                font-size: 1.2rem;
                font-weight: 400;
                background-color: $header-service-bg;

                &:hover {
                    color: $header-service-color-hover;
                }
            }

            @include media(xl) {
                font-size: 1.2rem;
            }
        }

        &:hover {
            color: $header-color-hover;
        }

        &::before {
            content: '';
            width: 26px;
            height: 24px;
            margin-right: 8px;
            background-size: contain;
            background-repeat: no-repeat;

            #{$root}--Sensus & {
                background-image: url('#{$publicpath}img/icon-translate--white.svg');
            }

            #{$root}:not(#{$root}--Sensus) & {
                background-image: url('#{$publicpath}img/icon-translate--black.svg');
            }

            #{$root}--HasServiceMenu & {
                @include media(lg) {
                    width: 16px;
                    height: 14px;
                    background-image: url('#{$publicpath}img/icon-translate--black.svg');
                }
            }
        }
    }

    &__TranslateButtonWrapper {
        @include media(lg) {
            #{$root}--HasServiceMenu & {
                padding: 0 16px 0 0;
                margin-right: 16px;
                border-right: 1px solid $header-service-border;
                display: flex;
            }
        }

        &:hover {
            #{$root}__TranslateTooltip {
                @include media(lg) {
                    display: block;
                }

                @include media(xl) {
                    display: none;
                }

                @media (hover: none) {
                    display: none;
                }
            }
        }
    }

    &__TranslateWrapper {
        position: relative;
    }

    &__TranslateClose {
        display: block;
        width: 14px;
        height: 14px;
        top: 0;
        right: 0;
        position: absolute;
        border: none;
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('#{$publicpath}img/close.svg');
        cursor: pointer;
    }

    &__TranslateTooltip {
        display: none;
        background-color: white;
        padding: 22px 24px;
        border-radius: 4px;
        left: -216px;
        top: 40px;
        position: absolute;
        z-index: 1;
        box-shadow: 0 3px 4px 0 rgba(black, 0.15);
        width: 182px;

        @include media(lg) {
            left: -116px;
        }

        &::before {
            content: '';
            width: 0;
            height: 0;
            top: -12px;
            right: 24px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid white;
            position: absolute;
        }
    }

    &__TranslateModal {
        display: none;
        background-color: white;
        padding: 22px 24px;
        border-radius: 4px;
        left: -216px;
        top: 40px;
        position: absolute;
        box-shadow: 0 3px 4px 0 rgba(black, 0.15);
        width: 280px;
        z-index: 1;

        @include media(md) {
            left: -214px;
        }

        @include media(xl) {
            left: -165px;
        }

        &::before {
            content: '';
            width: 0;
            height: 0;
            top: -12px;
            right: 24px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid white;
            position: absolute;
        }

        &--Visible {
            display: block;
        }

        select {
            margin-bottom: 12px !important;
            width: 100%;
            display: block;
            color: $text;
            font-weight: bold;
            font-size: 1.4rem;
            letter-spacing: 0.36px;
            padding: 12px 32px;
            border: 1px solid $gray-20;
            border-radius: 4px;
            background-color: transparent;
            appearance: none;
            background-image: url('#{$publicpath}img/icon-arrow-down.svg');
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: right 12px center;
        }
    }

    &__TranslateTitle {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 8px;
        padding-right: 20px;
    }

    &__TranslateDescription {
        font-size: 1.4rem;
        margin-bottom: 12px;
        line-height: 1.25;
    }
}
