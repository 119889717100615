@import 'Styles/includes';

/**
*
* NavMainButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NavMainButton {
    $root: &;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    height: 0;
    width: 100%;
    bottom: 45px;
    left: 0;
    right: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in;

    &--Expanded {
        @include media(lg) {
            pointer-events: all;
        }
    }

    &--Stuck {
        position: absolute;
        top: 0px;
    }

    &--Hide {
        opacity: 0;
        pointer-events: none;

        @include media(lg) {
            opacity: 1;
        }
    }

    &--HideDesktop {
        @include media(lg) {
            opacity: 0;
            pointer-events: none;
        }
    }

    @include media(lg) {
        top: -5px;
        @include u-wrap(article);
    }

    &__Button {
        position: relative;
        cursor: pointer;
        display: block;
        height: 68px;
        width: 68px;
        margin: -42px 15px;
        padding: 0;
        border: none;
        background-color: transparent;
        outline: 0;
        overflow: visible;
        -webkit-transform: translateZ(0);
        -webkit-tap-highlight-color: rgba(black, 0);

        @include media(lg) {
            margin: 30px 0;
        }

        #{$root}--Expanded & {
            cursor: pointer;
            width: 170px;
        }
    }

    &__Main {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        width: 68px;
        padding: 0;
        background-color: $button-bg;
        box-shadow: 2px 2px 7px 0 rgba(black, 0.3);
        border-radius: 50%;
        pointer-events: all;
        cursor: pointer;
        transition: background-color 0.2s ease-in;
        outline: 0;

        #{$root}--Open & {
            background-color: white;
        }

        #{$root}:hover & {
            background-color: $button-bg-hover;

            @media (hover: none) {
                background-color: $button-bg;
            }
        }

        #{$root}--Open:hover & {
            background-color: darken(white, 5%);

            @media (hover: none) {
                background-color: white;
            }
        }
    }

    &__StartCap {
        position: absolute;
        top: 5px;
        right: 30px;
        display: block;
        width: 30px;
        height: 58px;
        border-top-left-radius: 120px;
        border-bottom-left-radius: 120px;
        background-color: white;
        // box-shadow: 2px 2px 7px 0 rgba(black, 0.3);
        transition: transform 0.2s ease-out;

        // TODO: Add handling for mobile
        display: none;

        #{$root}--Expanded & {
            transform: translate(-110px, 0px);
        }

        @include media(lg) {
            display: block;
        }
    }

    &__Expander {
        position: absolute;
        top: 5px;
        right: 31px;
        display: block;
        width: 1px;
        height: 58px;
        background-color: white;
        transform-origin: right;
        transition: transform 0.2s ease-out;

        // TODO: Add handling for mobile
        display: none;

        #{$root}--Expanded & {
            transform: scale(110, 1);
        }

        @include media(lg) {
            display: block;
        }
    }

    &__Text {
        position: absolute;
        top: 5px;
        left: 120px;
        height: 58px;
        color: $link-color;
        font-size: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        line-height: 58px;
        border-radius: 50px;
        transition: transform 0.2s ease-out;
        opacity: 0;

        // TODO: Add handling for mobile
        display: none;

        #{$root}--Expanded & {
            transform: translate(-81px, 0px);
            opacity: 1;
        }

        @include media(lg) {
            display: block;
        }
    }

    &__Icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        position: relative;
        width: 23px;
        height: 18px;

        #{$root}--Open & {
            width: 23px;
            height: 22px;
        }

        span {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 3px;
            background-color: white;
            transition: all 0.25s ease-out;

            #{$root}--Open & {
                background-color: $link-color;
            }

            &:first-child {
                #{$root}--Open & {
                    transform: rotate(45deg);
                    transform-origin: left;
                    margin-left: 1px;
                    width: 125%;
                }
            }

            &:nth-child(2) {
                top: calc((100% / 2) - 2px);
                #{$root}--Open & {
                    opacity: 0;
                }
            }

            &:last-child {
                bottom: 0;
                width: 70%;

                #{$root}--Open & {
                    transform: rotate(-45deg);
                    transform-origin: left;
                    margin-left: 1px;
                    width: 125%;
                }
            }
        }
    }
}
