@import 'Styles/includes';

/**
*
* HeaderSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeaderSearch {
    $root: &;

    position: absolute;
    left: 0;
    top: $header-height;
    z-index: -1;
    background-color: white;
    width: 100%;

    &:global {
        animation: slideDown 250ms ease forwards;
    }

    @include media(lg) {
        display: block;
        top: $header-height-lg;
    }

    &--HasServiceMenu {
        @include media(lg) {
            top: calc($header-height-lg + $service-height);
        }
    }

    &__Container {
        @include u-wrap(article);
    }

    &__Field {
        width: calc(100% - 50px);
        height: 56px;
        border: none;
        appearance: none;
        outline: none;
        font-family: inherit;
        font-size: 2rem;
        background-color: white;

        @include media(md) {
            font-size: 2.6rem;
            height: 75px;
        }

        &::-webkit-search-cancel-button {
            display: none;
        }
    }

    &__FieldContent {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &__Close {
        appearance: none;
        border: none;
        background: white;
        width: 50px;
        height: 56px;
        background-image: url('#{$publicpath}img/icon-close--gray.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: right center;
        cursor: pointer;

        @include media(md) {
            background-size: 28px;
            height: 75px;
        }
    }

    &__Fetching {
        display: flex;
        justify-content: center;
        margin: 16px 0 36px 0;
    }
}
