@import 'Styles/includes';

/**
*
* Footer
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Footer {
    $root: &;

    background: $footer-bg;

    &--Sensus {
        display: none;
        margin-top: 50px;

        @include media(md) {
            display: flex;
        }
    }

    @media print {
        display: none !important;
    }

    &__Container {
        @include u-wrap(article);

        width: 100%;
        margin: 0 auto;
        color: $footer-color;
    }

    &__Nav {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        padding: 48px 0 36px;

        @include media(md) {
            gap: 40px;
        }

        #{$root}--Sensus & {
            display: none;
        }

        #{$root}--ColumnsTwo & {
            @include media(sm) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media(lg) {
                gap: 80px;
            }
        }

        #{$root}--ColumnsThree & {
            @include media(md) {
                grid-template-columns: repeat(3, 1fr);
                gap: 64px;
            }
        }

        #{$root}--ColumnsFour & {
            @include media(sm) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media(lg) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: bold;

        @include media(sm) {
            margin-bottom: 24px;
        }
    }

    &__Item {
        margin: 12px 0;
        line-height: 1.2;
        font-size: 1.6rem;
    }

    &__Text {
        max-width: 600px;
        white-space: pre-line;

        p {
            margin: 12px 0;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        a {
            color: $footer-link-color;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &::after {
                content: '';
                display: none;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left center;
                margin-left: 4px;
                width: 12px;
                height: 12px;
            }

            #{$root}--Sensus & {
                @include href-external-theme() {
                    &::after {
                        display: inline-block;
                        background-image: url('#{$publicpath}img/icon-external-link--red.svg');
                    }
                }
            }

            #{$root}:not(#{$root}--Sensus) & {
                @include href-external-theme() {
                    &::after {
                        display: inline-block;
                        background-image: url('#{$publicpath}img/icon-external-link--white.svg');
                    }
                }
            }
        }
    }

    &__Link {
        color: $footer-link-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: '';
            display: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            margin-left: 8px;
            width: 12px;
            height: 12px;
        }

        #{$root}--Sensus & {
            @include href-external-theme() {
                &::after {
                    display: inline-block;
                    background-image: url('#{$publicpath}img/icon-external-link--red.svg');
                }
            }
        }

        #{$root}:not(#{$root}--Sensus) & {
            @include href-external-theme() {
                &::after {
                    display: inline-block;
                    background-image: url('#{$publicpath}img/icon-external-link--white.svg');
                }
            }
        }

        #{$root}--Gavleborg & {
            @include href-external-theme() {
                &::after {
                    background-image: url('#{$publicpath}img/icon-external-link--black.svg')!important;
                }
            }
        }
    }

    &__Footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 36px;

        @include media(sm) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 36px 0;
        }

        #{$root}--Sensus &,
        #{$root}--HasColumns & {
            border-top: 1px solid $footer-border;
        }
    }

    &__Row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
        margin-bottom: 48px;

        @include media(sm) {
            gap: 0;
            margin-bottom: 0;
        }
    }

    &__Mucf {
        margin-right: 5px;

        img {
            width: 125px;
            height: 60px;
        }

        @include media(sm) {
            margin-right: 29px;
        }

        #{$root}--Sensus & {
            display: none;
        }
    }

    &__Logo {
        width: 105px;
        height: 21px;
        margin-right: 5px;

        @include media(sm) {
            margin-right: 29px;
        }
    }

    &__InfoWrapper {
        display: flex;
        flex-grow: 1;
    }

    &__Info {
        font-size: 1.4rem;
    }
}
