@import 'Styles/includes';

/**
*
* Logo
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Logo {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &__Image {
        width: 100%;
        height: 100%;
    }
}
