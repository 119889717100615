@use "sass:selector";

@function maxwidth($size: 'site') {
    @return map-get($maxwidths, $size);
}

@mixin media($key) {
    @if map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if map-has-key($breakpoints, $key) {
        @media (max-width: (map-get($breakpoints, $key) - 1)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin agenda-2030-modifiers($icon: false) {
    $names: (
        NoPoverty: 'no-poverty',
        ZeroHunger: 'zero-hunger',
        GoodHealth: 'good-health',
        QualityEducation: 'quality-education',
        GenderEquality: 'gender-equality',
        CleanWater: 'clean-water',
        AffordableCleanEnergy: 'affordable-clean-energy',
        DecentWorkGrowth: 'decent-work-growth',
        Industry: 'industry',
        ReducedInequalities: 'reduced-inequalities',
        SustainableCities: 'sustainable-cities',
        ResponsibleConsumption: 'responsible-consumption',
        ClimateAction: 'climate-action',
        LifeBelowWater: 'life-below-water',
        LifeOnLand: 'life-on-land',
        Peace: 'peace',
        Partnerships: 'partnerships',
    );
    @each $modifier, $name in $names {
        &--#{$modifier} {
            background-color: map-get($agenda2030, $name);
            @if $icon {
                background-image: url('#{$publicpath}img/agenda2030/#{$name}.svg');
            }
        }
    }
}

@mixin sensus-bg-modifiers($includeLinkColor: false, $includeIconColor: false) {
    @each $modifier, $color in $sensus {
        $contrast: map-get($color, contrast);
        &--Background#{$modifier} {
            color: $contrast;
            background-color: map-get($color, color);

            @if $includeLinkColor and $contrast == white {
                a {
                    color: $contrast;

                    &:hover {
                        color: $contrast;
                    }
                }
            }

            @if $includeIconColor and $contrast == white {
                i {
                    color: $contrast;
                }
            }
        }
    }
}

@mixin href-external($domain: 'sensus.se') {
    &[href]:not([type='submit']):not([type='button']):not([href^='mailto:']):not([href^='tel:']):not([href='']):not([href^='#']):not([href^='/']):not([href*='cdn.sensus.se']):not([href*='cdnst.sensus.se']):not([href*='cdnadb.sensus.se']):not([href*='stagecdn.sensus.se']):not([href*='stagecdnadb.sensus.se']):not([href^='http://#{$domain}']):not([href^='https://#{$domain}']):not([href*='beta.#{$domain}']):not([href*='www.#{$domain}']):not([href*='stage.#{$domain}']) {
        @content;
    }
}

@mixin href-external-theme() {
    :global(.theme-sensus) & {
        @include href-external('sensus.se') {
            @content;
        }
    }

    :global(.theme-dalarna) & {
        @include href-external('dalarna.rattighetscentrum.se') {
            @content;
        }
    }

    :global(.theme-norrbotten) & {
        @include href-external('norrbotten.rattighetscentrum.se') {
            @content;
        }
    }

    :global(.theme-vasterbotten) & {
        @include href-external('rattighetscentrumvasterbotten.se') {
            @content;
        }
    }

    :global(.theme-gavleborg) & {
        @include href-external('diskriminering.org') {
            @content;
        }
    }

    :global(.theme-stockholmnorr) & {
        @include href-external('antidiskrimineringstockholm.se') {
            @content;
        }
    }

    :global(.theme-uppsala) & {
        @include href-external('antidiskrimineringuppsala.se') {
            @content;
        }
    }
}

@mixin href-external-theme-icon($pseudo: 'after') {
    :global(.theme-sensus) & {
        @include href-external('sensus.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--red.svg');
            }
        }
    }

    :global(.theme-dalarna) & {
        @include href-external('dalarna.rattighetscentrum.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--red.svg');
            }
        }
    }

    :global(.theme-norrbotten) & {
        @include href-external('norrbotten.rattighetscentrum.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--red.svg');
            }
        }
    }

    :global(.theme-vasterbotten) & {
        @include href-external('rattighetscentrumvasterbotten.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--yellow-80.svg');
            }
        }
    }

    :global(.theme-gavleborg) & {
        @include href-external('diskriminering.org') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--red-70.svg');
            }
        }
    }

    :global(.theme-stockholmnorr) & {
        @include href-external('antidiskrimineringstockholm.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--green-80.svg');
            }
        }
    }

    :global(.theme-uppsala) & {
        @include href-external('antidiskrimineringuppsala.se') {
            &::#{$pseudo} {
                background-image: url('#{$publicpath}img/icon-external-link--blue-80.svg');
            }
        }
    }
}

@mixin u-loader($size: 50px, $color: $sensus-red, $bgColor: rgba($color, 0.2)) {
    $lineWidth: $size / 10;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: auto;
    width: $size;
    height: $size;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        display: block;
        width: calc(100% - #{($lineWidth * 2)});
        height: calc(100% - #{($lineWidth * 2)});
        border-radius: 50%;
    }

    &::before {
        position: relative;
        border: $lineWidth solid $bgColor;
        border-left-color: $color;
        transform: translateZ(0);
    }
}
