@import 'Styles/includes';

/**
*
* NavMainContentItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NavMainContentItem {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    line-height: 3rem;
    border-bottom: 1px solid $mobile-menu-border;

    &__ItemRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__Link {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        position: relative;
        color: white;
        font-weight: 600;
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding: 25px 24px;

        #{$root}:not(#{$root}--Sensus) & {
            padding: 22px 24px;
        }

        #{$root}--Vasterbotten & {
            color: $gray-60;
        }

        &::before,
        &::after {
            content: '';
            height: 12px;
            width: 12px;
            background-size: contain;
            background-image: url('../#{$publicpath}img/icon-arrow-thin--white.svg');
            background-repeat: no-repeat;
            background-position: center left;

            #{$root}--Vasterbotten & {
                background-image: url('../#{$publicpath}img/icon-arrow-thin.svg');
            }
        }

        &::before {
            display: none;
            margin-right: 8px;
        }

        &::after {
            margin-left: 12px;
        }

        &--External {
            &::after {
                margin-bottom: 4px;
                background-image: url('../#{$publicpath}img/icon-external-link--white.svg');

                #{$root}--Vasterbotten & {
                    background-image: url('../#{$publicpath}img/icon-external-link--black.svg');
                }
            }

            &:not(#{$root}__Link--SubSub)::after {
                height: 14px;
                width: 14px;
                margin-bottom: 0;
            }
        }

        &--Sub {
            padding: 16px 24px 16px 44px;
            font-size: 1.6rem;
            line-height: 3rem;
        }

        &--SubSub {
            padding: 12px 0 12px 56px;
            font-size: 1.6rem;
            font-weight: normal;
            line-height: 3rem;

            &::before {
                display: inline;
            }

            &:not(#{$root}__Link--External)::after {
                display: none;
            }
        }

        &--Active {
            border-bottom: 1px solid rgba(white, .5);
        }

        #{$root}--ParentOpen & {
            animation: fadeInText 300ms ease 150ms forwards;
        }
    }

    &__Expand {
        cursor: pointer;
        appearance: none;
        border: none;
        outline: none;
        background-size: 16px;
        width: 32px;
        height: 22px;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../#{$publicpath}img/icon-plus-white.svg');
        padding: 12px;
        margin-right: 24px;

        #{$root}--Vasterbotten & {
            background-image: url('../#{$publicpath}img/icon-plus-black.svg');
        }

        &--Expanded {
            background-image: url('../#{$publicpath}img/icon-minus-white.svg');

            #{$root}--Vasterbotten & {
                background-image: url('../#{$publicpath}img/icon-minus-black.svg');
            }
        }
    }

    &__List {
        width: 100%;
        padding: 8px 0 0;
        background-color: $mobile-menu-list-bg;
        box-shadow: inset 0 -73px 8px -72px rgba(black, .1), inset 0 73px 8px -72px rgba(black, .1);
        opacity: 0;
        transform: scale(.975);
        transform-origin: left center;
        transition: transform .3s ease-in-out, opacity .3s ease-in-out;
        will-change: transform;

        &--Expanded {
            animation: fadeInSubNav .25s .15s ease forwards;
        }

        &--Sub {
            padding: 8px 0;
            background-color: $mobile-menu-sub-bg;
        }
    }
}

@keyframes fadeInSubNav {
    from {
        transform: scale(0.975);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeInText {
    0% {
        margin-top: 20px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
