@import 'Styles/includes';

/**
*
* BasePage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.BasePage {
    // overflow: hidden;

    &--Wrapper {
        margin: 0 auto;
    }

    &__Container {
        padding-bottom: 104px;

        @include media(md) {
            min-height: calc(
                100vh - 240px
            ); // 240px = footer (+ footer margin) + header
            padding-bottom: 0;
        }

        &--RegularHeight {
            padding-bottom: 0;
        }
    }
}
