@import 'Styles/includes';

/**
*
* SocialMedia
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SocialMedia {
    $root: &;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    &__Icon {
        fill: white;
        width: 24px;
        height: 24px;
    }

    &__Link {
        display: flex;
        margin-right: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 24px;
        height: 24px;

        &:last-child {
            margin-right: 0;
        }

        #{$root}--Sensus & {
            &--Facebook {
                background-image: url('../#{$publicpath}img/icon-facebook--gray-35.svg');
            }

            &--Instagram {
                background-image: url('../#{$publicpath}img/icon-instagram--gray-35.svg');
            }

            &--LinkedIn {
                background-image: url('../#{$publicpath}img/icon-linkedin--gray-35.svg');
            }
        }

        #{$root}:not(#{$root}--Sensus) & {
            &--Facebook {
                background-image: url('../#{$publicpath}img/icon-facebook--white.svg');
            }

            &--Instagram {
                background-image: url('../#{$publicpath}img/icon-instagram--white.svg');
            }

            &--LinkedIn {
                background-image: url('../#{$publicpath}img/icon-linkedin--white.svg');
            }
        }

        #{$root}--Gavleborg & {
            &--Facebook {
                background-image: url('../#{$publicpath}img/icon-facebook--black.svg')!important;
            }

            &--Instagram {
                background-image: url('../#{$publicpath}img/icon-instagram--black.svg')!important;
            }

            &--LinkedIn {
                background-image: url('../#{$publicpath}img/icon-linkedin--black.svg')!important;
            }
        }
    }
}