@import 'Styles/includes';

/**
*
* NavMainContent
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NavMainContent {
    $root: &;

    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: $mobile-menu-bg;
    overflow-x: hidden;
    overflow-y: hidden;

    &__InnerWrapper {
        height: calc(100vh - 74px);
        overflow-y: auto;
        -ms-overflow-style: none;
        padding-bottom: 120px;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }

    &__List {
        padding: 0 0 25px;
        transition: left 0.2s ease-in, right 0.2s ease-in;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__ServiceLink {
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        font-weight: 400;
        text-decoration: none;
        color: #fff;
        white-space: nowrap;
        display: block;
        padding: 13px 25px;

        #{$root}--Vasterbotten & {
            color: $gray-60;
        }
    }

    &__HeaderWrapper {
        box-shadow: 0 -3px 30px 0 rgba(black, 0.2);
    }

    &__HeaderTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    &__LogoLink {
        display: block;
        padding: 8px 0;
        margin: 0 auto;
    }

    &__Logo {
        width: 105px;
        height: 22px;
    }

}
