@import 'Styles/includes';

/**
*
* HeaderSubMenuPromotion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeaderSubMenuPromotion {
    color: $text;
    text-decoration: none;

    &__Image {
        height: 150px;
        width: 100%;
        background-color: $gray-10;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-bottom: 16px;
    }

    &__Title {
        margin-bottom: 8px;
        font-weight: bold;
        line-height: 1.25;
        font-size: 1.8rem;
    }

    &__Text {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-bottom: 16px;
    }
}
