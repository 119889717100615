@import 'Styles/includes';

.AjaxLoader {
    $green: #008744;
    $blue: #0057e7;
    $red: #d62d20;
    $yellow: #ffa700;
    white: #eee;

    // scaling... any units
    $width: 100px;

    width: 40px;
    height: 40px;

    &__Circular {
        transform-origin: center center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        &:global {
            animation: spin 2s linear infinite;
        }
    }

    &__Path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        stroke: $loader-color;
        animation: dashLoader 1.5s ease-in-out infinite;
    }
}

@keyframes dashLoader {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
