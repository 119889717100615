@import 'Styles/includes';

/**
*
* SearchAutocompleteResult
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SearchAutocompleteResult {
    $root: &;

    opacity: 0;
    width: 100%;
    background-color: white;
    color: $text;

    &--OnHero {
        transition: all 0.1s ease;
        position: absolute;
        border-radius: 4px;
        left: 0;
        transform: translateY(-16px);
        border: solid 1px $gray-20;
        top: 56px;
        margin-bottom: 0;
        box-shadow: 0 0 14px 0 rgba(black, 0.22);
    }

    &--NoHits,
    &--HasHits {
        opacity: 1;
        z-index: 1;
        margin-top: 4px;
        transform: translateY(0);
        margin-bottom: 16px;
    }

    &__Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__ShowAllHits {
        display: flex;
        justify-content: center;
        width: 260px;
        text-align: left;
        padding: 16px 24px;
        background-color: $button-bg;
        color: white;
        margin: 8px 0;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.36px;
        border-radius: 4px;

        #{$root}--OnHero & {
            margin: 8px;
            width: calc(100% - 16px);
        }

        &:hover {
            background: $button-bg-hover;

            @media (hover: none) {
                background: $button-bg;
            }
        }

        &:active {
            background: $button-bg-active;
        }

        span {
            padding-right: 24px;
            background-image: url('#{$publicpath}img/icon-arrow-right--white.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: right center;
        }
    }

    &__NoHits {
        padding: 16px 0;

        #{$root}--OnHero & {
            padding: 12px 16px;
        }
    }

    &__Hit {
        width: 100%;
        overflow: hidden;
        text-align: left;
        color: $text;
        text-decoration: none;
        padding: 8px 0;
        border-top: 1px solid $gray-10;

        #{$root}--OnHero & {
            padding: 12px 16px;
        }

        @include media(md) {
            padding: 24px 0;

            #{$root}--OnHero & {
                padding: 16px 24px;
            }
        }

        &:first-of-type {
            #{$root}--OnHero & {
                border-top: none;
            }
        }

        &:hover {
            #{$root}--OnHero & {
                background-color: $gray-10;
                @media (hover: none) {
                    background-color: white;
                }
            }
        }
    }

    &__Type {
        color: $text;
        text-transform: uppercase;
        letter-spacing: 0.33px;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 2px;
        line-height: 1.25;
        display: block;
        margin-bottom: 4px;
    }

    &__HitBreadcrumbs {
        display: none;

        @include media(md) {
            display: block;
            margin-bottom: 8px;
        }
    }

    &__HitBreadcrumb {
        text-decoration: none;
        letter-spacing: 0.33px;
        font-size: 1.2rem;
        color: rgba($text, 0.75);
        position: relative;

        &:after {
            content: '/';
            margin: 0 8px;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }

    &__HitTitle {
        font-weight: bold;
        line-height: 1.33;
        font-size: 1.8rem;
        margin-bottom: 8px;
        display: inline-block;
        position: relative;

        #{$root}__Hit:hover & {
            @include media(md) {
                text-underline-offset: 2px;
                text-decoration-thickness: 1px;
                text-decoration: underline;
            }

            // #{$root}--OnHero & {

            // }
        }

        #{$root}--OnHero & {
            font-size: 1.6rem;
        }
    }

    &__HitPreamble {
        margin: 0 0 16px 0;
        line-height: 1.33;
    }

    &__MetaList {
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 12px;
    }

    &__Distance {
        margin: -7px 16px 0 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 0.9px;
        display: inline-flex;
        align-items: center;
        color: #961313;
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(175, 20, 50, 0.23);
    }

    &__MetaItem {
        color: $black;
        font-size: 1.1rem;
        margin-bottom: 6px;
        position: relative;
        text-transform: uppercase;
        flex-shrink: 0;
        letter-spacing: 0.8px;
        margin-left: 36px;

        &:first-of-type {
            margin-left: 0;
        }

        &::before {
            content: '';
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0px;
            padding: inherit;
        }

        &--Location {
            padding-left: 18px;

            &::before {
                width: 12px;
                height: 12px;
                background-image: url('#{$publicpath}img/icon-location-black.svg');
                left: 0px;
            }
        }

        &--Price {
            padding-left: 16px;

            @include media(md) {
                padding-left: 26px;
            }

            #{$root}--OnHero & {
                display: none;

                @include media(md) {
                    display: block;
                }
            }

            &::before {
                top: -2px;
                left: -8px;
                width: 17px;
                height: 13px;
                background-image: url('#{$publicpath}img/icon-card.svg');

                @include media(md) {
                    left: 0;
                }
            }
        }

        &--Date {
            padding-left: 16px;

            @include media(md) {
                padding-left: 26px;
            }

            &::before {
                left: -8px;
                top: -4px;
                width: 17px;
                height: 17px;
                background-image: url('#{$publicpath}img/icon-clock.svg');

                @include media(md) {
                    left: 0;
                }
            }
        }
    }
}
