@import 'Styles/includes';

/**
*
* HeaderSubMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeaderSubMenu {
    $root: &;

    top: 75px;
    opacity: 0;
    left: 0;
    position: absolute;
    background: white;
    border-radius: 4px;
    color: $black;
    width: 100%;
    //z-index: -1;
    max-width: maxwidth(article);
    cursor: default;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 8px 12px -6px rgba(black, 0.22);
    will-change: transform;
    z-index: 1;
    display: none;

    &:not(#{$root}--Sensus) {
        top: 110px;
    }

    &--Open {
        display: block;
        animation: megaMenu 0.25s 0.15s ease forwards;
    }

    &__Wrapper {
        margin: 0 auto;
        padding: 46px 80px 40px 80px;
        display: flex;
        justify-content: space-between;
    }

    &__Navigation {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        #{$root}--Promotion & {
            width: 65%;
            border-right: 1px solid #eaeaea;
            padding-right: 25px;
        }
    }

    &__Links {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        width: 33%;
        padding-right: 16px;

        #{$root}--Promotion & {
            width: 50%;
            padding-right: 24px;
        }
    }

    &__Link {
        color: $header-sub-color;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 12px;

        &:hover {
            color: $header-sub-color-hover;

            &::after {
                transform: translate3d(4px, 0, 0);
            }
        }

        &::after {
            content: '';
            will-change: transform;
            transition: transform 0.15s ease;
            transform: translate3d(0, 0, 0);
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            margin-left: 16px;
            width: 10px;
            height: 10px;

            #{$root}--Sensus & {
                background-image: url('#{$publicpath}img/icon-arrow-right--red.svg');
            }

            #{$root}:not(#{$root}--Sensus) & {
                background-image: url('#{$publicpath}img/icon-arrow-right--black.svg');
            }
        }

        #{$root}--Sensus & {
            @include href-external-theme() {
                &::after {
                    background-image: url('#{$publicpath}img/icon-external-link--red.svg');
                }
            }
        }

        #{$root}:not(#{$root}--Sensus) & {
            @include href-external-theme() {
                &::after {
                    background-image: url('#{$publicpath}img/icon-external-link--black.svg');
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $header-sub-color;
        }
    }

    &__SubLinks {
        display: block;
    }

    &__SubLink {
        color: $black;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: normal;
        letter-spacing: 0.4px;
        margin-bottom: 8px;
        line-height: 1.25;

        &::before {
            content: '';
            will-change: transform;
            transition: transform 0.15s ease;
            display: inline-block;
            background-image: url('#{$publicpath}img/icon-arrow-left.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            margin-right: 12px;
            transform: rotate(180deg) translate(0px, 0px);
            width: 8px;
            height: 8px;
        }

        &::after {
            content: '';
            display: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            margin-left: 8px;
            width: 12px;
            height: 12px;
            margin-bottom: 2px;
        }

        @include href-external-theme() {
            &::after {
                display: inline-block;
                background-image: url('#{$publicpath}img/icon-external-link--black.svg');
            }
        }

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;

            &::before {
                transform: rotate(180deg) translate(-2px, 0px);
            }
        }
    }

    &__Promotion {
        display: none;

        @include media(md) {
            width: calc(35% - 50px);
            position: relative;
            display: block;
        }
    }
}

@keyframes megaMenu {
    0% {
        opacity: 0;
        transform: translate(-50%, -24px);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -8px);
    }
}
