@mixin u-wrap($list: (), $default: true) {
    margin-left: auto;
    margin-right: auto;

    @if $default {
        @if contains($list, site) {
            max-width: (map-get($maxwidths, site) + 60px);
        }

        @if contains($list, article) {
            max-width: (map-get($maxwidths, article) + 60px);
        }

        padding-left: 15px;
        padding-right: 15px;

        @include media(sm) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media(lg) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media(xl) {
            padding-left: 0;
            padding-right: 0;

            @if contains($list, site) {
                max-width: (map-get($maxwidths, site) + 120px);
            }

            @if contains($list, article) {
                max-width: (map-get($maxwidths, article));
            }
        }
    } @else {
        @if contains($list, site) {
            max-width: (map-get($maxwidths, site));
        }

        @if contains($list, article) {
            max-width: (map-get($maxwidths, article));
        }
    }
}
